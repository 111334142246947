import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useContext, useEffect, useState } from "react";
import { Button, Loader, AttachmentPreview } from "@crowd/ui-kit";
import Sticky from "react-stickynode";
import { useMediaQuery } from "react-responsive";

import PageTitle from "../../presentational/PageTitle/PageTitle";
import { DiscussionPageParams } from "./Discussion.interface";
import { Stage } from "../../../types/Stage.interface";
import { RootState } from "../../../types/State.interface";
import ItemLifecycle from "../../presentational/ItemLifecycle/ItemLifecycle";
import StageHeader from "../../presentational/StageHeader/StageHeader";
import { Discussion } from "../../../types/Discussion.interface";
import ItemStats from "../../presentational/ItemStats/ItemStats";
import { BlogPost } from "../../../types/BlogPost.interface";
import PostAddFavorite from "../../presentational/PostAddFavorite/PostAddFavorite";
import AddCommentForm from "../../containers/Forms/AddCommentForm/AddCommentForm";
import { approveBlogpostSummary, deleteBlogPost, getBlogPost } from "../../../store/actions/BlogActions";
import GenerationModal from "../Generation/GenerationModal/GenerationModal";
import StageSelectorModal from "../../containers/Modals/StageSelectorModal/StageSelectorModal";
import BlogModal from "../../containers/Modals/BlogModal/BlogModal";
import Tooltip from "../../presentational/Tooltip/Tooltip";
import AddPostSummary from "../../containers/Forms/addPostSummary/addPostSummary";
import {
  setAppColor,
  showFooter,
  showHeader,
  hideInfoModal,
  showConfirmInfoModal,
  showUserModal,
} from "../../../store/actions/LayoutActions";
import { AppColorsEnum } from "../../../App.interface";
import { itemIsFinished } from "../../../utils";
import "./Discussion.scss";
import CommentCardList from "../../containers/Lists/CommentCardList/CommentCardList";
import { AuthAction, AuthActionParam, AuthActionType, ComponentType } from "../../../services/sudirService";
import { AppContext } from "../../../Root";
import { phoneWidth, tabletWidth } from "../../../utils/constants/widthConstants";
import UserService from "../../../services/userService";
import { selectIsUserReady } from "../../../store/selectors/profile";

const DiscussionPage = () => {
  const appContext = useContext(AppContext);
  const isPhone = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const headerHeight = isPhone || isTablet ? 65 : 81;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const allStages = useSelector((state: RootState) => state.stage.allStages);
  const _discussions = useSelector((state: RootState) => state.discussion.byType?.PROJECT_DISCUSSIONS);
  const { stageId } = useParams<DiscussionPageParams>();
  const [stage, setStage] = useState<Stage | null>(null);
  const [activeGenerationStages, setActiveGenerationStages] = useState<Stage[] | null>(null);
  const [generationModalOpen, setGenerationModalOpen] = useState(false);
  const [generationStage, setGenerationStage] = useState(null);
  const [stageSelectorModalOpen, setStageSelectorModalOpen] = useState(false);
  const [stageSelectorModalHeader, setStageSelectorModalHeader] = useState("");
  const [stageSelectorModalListHeader, setStageSelectorModalListHeader] = useState("");
  const [stageSelectorModalMessage, setStageSelectorModalMessage] = useState("");
  const [stageSelectorModalStages, setStageSelectorModalStages] = useState([]);
  const [discussion, setDiscussion] = useState<Discussion | null>(null);
  // const [comments, setComments] = useState<Comment[]>([]);
  // const [commentsPaging, setCommentsPaging] =
  //   useState<PagingResponseParameters>(null);
  const [noPostsAdded, setNoPostsAdded] = useState<boolean>(false);
  const [post, setPost] = useState<BlogPost | null>(null);
  // const [pageNumber, setPageNumber] = useState(null);
  const [showAddComment, setShowAddComment] = useState(false);
  const [addPostSummaryOpen, setAddPostSummaryOpen] = useState(false);
  const [isBlogModalOpen, setBlogModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const isUserReady = useSelector(selectIsUserReady);
  const [dataForReadyUserLoaded, setDataForReadyUserLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(setAppColor(AppColorsEnum.LIGHTBLUE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  // ACTIONS AFTER AUTH
  useEffect(() => {
    if (!post) return;
    if (!activeGenerationStages) return;
    if (!dataForReadyUserLoaded) return;

    const actions = appContext.sudirService.getActions(ComponentType.Discussion);
    if (actions.length) {
      const writeComment = actions.find((act) => act.type === AuthActionType.WriteComment);
      if (writeComment) {
        openRootForm();
      }

      const proposeIdeaAction = actions.find((act) => act.type === AuthActionType.ProposeIdea);
      if (proposeIdeaAction && isPropositionAvailable()) {
        proposeIdea();
      }
    } else {
      const actions = appContext.sudirService.getActions(ComponentType.ProjectParticipants);
      if (actions?.length) {
        const openUserDetailsModalAction = actions.find((act) => act.type === AuthActionType.OpenUserDetailsModal);
        if (openUserDetailsModalAction) {
          UserService.getPerson(openUserDetailsModalAction.args.userId).then(({ data }) => {
            dispatch(showUserModal(data));
          });
        }
      }
    }
  }, [post, activeGenerationStages, dataForReadyUserLoaded]);

  useEffect(() => {
    if (allStages && user && (!user.loggedIn || isUserReady)) {
      const st = allStages.find((stage) => stage.id === stageId) as Stage;

      if (st) {
        setStage(st);
        setActiveGenerationStages(allStages.filter((stage) => stage.type === "GENERATION" && stage.active));
      } else {
        history.push("/notfound");
      }
    }
  }, [allStages, user, isUserReady]);

  useEffect(() => {
    dispatchBlogPost();
  }, [stage, discussion, isUserReady]);

  useEffect(() => {
    discussion?.stageId !== stageId && _discussions !== undefined && setDiscussion(getCurrentDiscussion());
  }, [stage, _discussions]);

  const dispatchBlogPost = () => {
    if (stage && discussion !== null) {
      if (discussion) {
        dispatch(
          getBlogPost(
            discussion.ownerId,
            ({ data }) => setPost(data),
            () => {
              setDataForReadyUserLoaded(isUserReady);
              setLoading(false);
            }
          )
        );
      } else {
        if (discussion === undefined) {
          setNoPostsAdded(true);
        }
        setLoading(false);
      }
    }
  };

  const getCurrentDiscussion = (): Discussion => _discussions?.filter((discussion) => discussion.stageId === stageId)[0] as Discussion;

  const editPost = () => {
    setBlogModalOpen(true);
  };

  const removePost = () => {
    dispatch(
      showConfirmInfoModal("Вы действительно хотите удалить пост?", () => {
        dispatch(
          deleteBlogPost(post.id, () => {
            history.push(`/`);
          })
        );
        dispatch(hideInfoModal());
      })
    );
  };

  const approveSummary = () => {
    dispatch(approveBlogpostSummary(post.summary.id, dispatchBlogPost));
  };

  const addComment = () => {
    if (!user.loggedIn) {
      const data: AuthActionParam = {
        component: ComponentType.Discussion,
        type: AuthActionType.WriteComment,
      };

      const writeCommentAction = new AuthAction(data);
      return appContext.sudirService.authWithActions([writeCommentAction]);
    }

    openRootForm();
  };

  const openRootForm = () => {
    post.discussionPermissions?.includes("DISCUSSION_ADD_COMMENT") && setShowAddComment(true);
  };

  const isPropositionAvailable = () => {
    if (!activeGenerationStages.length) return false;
    if (user.teamMember || user.expert) return false;

    return true;
  };

  const proposeIdea = () => {
    if (!user.loggedIn) {
      const data: AuthActionParam = {
        component: ComponentType.Discussion,
        type: AuthActionType.ProposeIdea,
      };

      const proposeIdeaAction = new AuthAction(data);
      return appContext.sudirService.authWithActions([proposeIdeaAction]);
    }

    if (activeGenerationStages?.length === 1) {
      setGenerationStage(activeGenerationStages[0]);
      setGenerationModalOpen(true);
    } else {
      setStageSelectorModalOpen(true);
    }
  };

  const renderAddCommentButton = () => (
    <Button
      text={isPhone ? "Комментировать" : "Написать комментарий"}
      size={"m"}
      type={isPhone ? "text-button" : "filled"}
      icon={isPhone ? "ui-icon-comments" : ""}
      onClick={addComment}
    />
  );

  const renderProposeIdeaButton = () =>
    isPropositionAvailable() && (
      <Button
        text={"Предложить идею"}
        size={"m"}
        type={isPhone ? "text-button" : "outlined"}
        icon={isPhone ? "ui-icon-idea" : ""}
        onClick={proposeIdea}
      />
    );

  const renderEditPostButton = () =>
    post.permissions?.includes("BLOG_POST_EDIT") && (
      <Tooltip text={"Редактировать пост"} idx={post.id + "edit"} className={"discussion__buttons-icon"}>
        <i className="ui-icon-edit" onClick={editPost} />
      </Tooltip>
    );

  // const renderDeletePostButton = () =>
  //   post.permissions?.includes("BLOG_POST_DELETE") && (
  //     <Tooltip
  //       text={"Удалить пост"}
  //       idx={post.id + "delete"}
  //       className={"discussion__buttons-icon"}
  //     >
  //       <i className="ui-icon-delete" onClick={removePost} />
  //     </Tooltip>
  //   );

  const renderAddSummaryButton = () =>
    !post.summary &&
    post.permissions?.includes("BLOG_POST_ADD_SUMMARY") && (
      <Button
        text={"Добавить резюме"}
        size={"m"}
        type={isPhone ? "text-button" : "filled"}
        icon={isPhone ? "ui-icon-create" : ""}
        color={isPhone ? "" : "#FFB867"}
        onClick={() => setAddPostSummaryOpen(true)}
        classNames="add-summary-button"
      />
    );

  const renderStickyControls = () => (
    <Sticky
      top={headerHeight}
      innerZ={19}
      className={"discussion__buttons"}
      activeClass={"discussion__buttons-sticky"}
      innerClass={"discussion__buttons-inner"}
    >
      {!showAddComment ? (
        isPhone ? (
          <>
            <div className="discussion__buttons__buttons-row">
              {renderAddCommentButton()}

              {renderProposeIdeaButton()}

              {renderAddSummaryButton()}
            </div>

            {post.permissions?.includes("BLOG_POST_EDIT") && (
              <div className="discussion__buttons__icons-row">
                {renderEditPostButton()}
                {/* {renderDeletePostButton()} */}
              </div>
            )}
          </>
        ) : (
          <>
            {renderAddCommentButton()}

            {renderProposeIdeaButton()}

            {renderEditPostButton()}

            {/* {renderDeletePostButton()} */}

            {renderAddSummaryButton()}
          </>
        )
      ) : (
        <AddCommentForm
          discussionId={post.discussionId}
          cancel={() => setShowAddComment(false)}
          onAddComment={(comment) => {
            history.push({
              pathname: `/discussion/${comment.stageId}`,
              state: { updateFirstPage: true },
            });
          }}
        />
      )}
    </Sticky>
  );

  const renderSummary = () => (
    <div className="discussion__summary editor-content">
      <div className="discussion__summary-title">{post.summary?.title}</div>
      <div className="discussion__summary-content" dangerouslySetInnerHTML={{ __html: post.summary?.content || "" }} />
      <div className="discussion__summary-buttons">
        {!post.summary.approved && post.summary.permissions?.includes("SUMMARY_APPROVE") && (
          <Tooltip className={"discussion__summary-button"} text={"Опубликовать резюме"} idx={post.id + "send-summary"}>
            <div className="discussion__summary-send" onClick={approveSummary}>
              Опубликовать
            </div>
          </Tooltip>
        )}

        {post.summary.permissions?.includes("SUMMARY_EDIT") && (
          <Tooltip className={"discussion__summary-button"} text={"Редактировать резюме"} idx={post.id + "edit-summary"}>
            <i className="ui-icon ui-icon-edit discussion__summary-edit" onClick={() => setAddPostSummaryOpen(true)} />
          </Tooltip>
        )}
      </div>
    </div>
  );

  const updatePostContent = () => {
    dispatch(getBlogPost(discussion.ownerId, ({ data }) => setPost(data)));
    setBlogModalOpen(false);
  };

  const formNavigationLinkFn = (item, shouldNavigate?, shouldOpen?) => {
    let link = `/discussion/${item.stageId}/${item.id}`;
    if (shouldNavigate) {
      link += "?navigate=true";
    }
    if (shouldOpen) {
      link += "&openForm=true";
    }
    return link;
  };

  const getRootClasslist = () => {
    let classlist = "discussion";
    if (itemIsFinished(stage)) classlist += " discussion--off";
    return classlist;
  };

  return (
    <section className={getRootClasslist()}>
      {isLoading && (
        <div className="discussion__loader">
          <Loader />
        </div>
      )}

      {stage && post && (
        <>
          <div className="white-block discussion__white-block">
            <PageTitle text={`Обсуждай: ${post.title}`} />
            <div className="discussion__stats">
              <ItemLifecycle item={stage} type={"STAGE"} />
              <ItemStats icon={"comments"} count={post.discussionStatistics.totalComments} type={"COMMENTS"} />
              {stage.active && post.commentsEnabled && post.active && !post.hidden && (
                <PostAddFavorite post={post} size={"SMALL"} count={post.discussionStatistics.totalSubscriptions} />
              )}
            </div>
            <div className="discussion__content-wrapper">
              <div className="discussion__content">
                <p
                  className="discussion__text"
                  dangerouslySetInnerHTML={{
                    __html: post.content,
                  }}
                />
                {post.attachments?.length > 0 && (
                  <div className="discussion__attachments scroll-x">
                    {post.attachments.map((attachment) => (
                      <AttachmentPreview attachment={attachment} key={attachment.id} />
                    ))}
                  </div>
                )}
                {post.summary && renderSummary()}
                {(stage.active || (!stage.active && user.teamMember)) && renderStickyControls()}
              </div>

              <div className="discussion__logo">
                <img src={require("../../../assets/discussion/discussion-logo.svg").default} alt="discussion-logo" />
              </div>
            </div>
          </div>

          <div className="discussion__comments">
            <CommentCardList formNavigationLinkFn={formNavigationLinkFn} post={post} showPerPage={10} addComment={true} />
          </div>

          {generationModalOpen && Boolean(generationStage) && (
            <GenerationModal
              open={generationModalOpen}
              stage={generationStage}
              onStageSelect={(stage) => {
                setGenerationStage(stage);
                setGenerationModalOpen(true);
              }}
              onFormClose={() => {
                setGenerationModalOpen(false);
              }}
              onClose={() => {
                setGenerationModalOpen(false);
              }}
              onStageModalClose={() => {
                setGenerationModalOpen(false);
              }}
            />
          )}

          {stageSelectorModalOpen && (
            <StageSelectorModal
              classname="stage-selector-modal__short"
              isOpen={stageSelectorModalOpen}
              close={() => setStageSelectorModalOpen(false)}
              message={""}
              header={"Предложить идею по теме:"}
              onSelect={(stage) => {
                setGenerationStage(stage);
                setGenerationModalOpen(true);
              }}
              stages={activeGenerationStages}
            />
          )}

          {addPostSummaryOpen && (
            <AddPostSummary
              postId={post.id}
              summary={post.summary}
              onClose={() => setAddPostSummaryOpen(false)}
              isOpen={addPostSummaryOpen}
              onAddBlogPostSummary={dispatchBlogPost}
            />
          )}

          {isBlogModalOpen && (
            <BlogModal
              fromStage={true}
              onSuccess={() => {
                updatePostContent();
                setBlogModalOpen(false);
              }}
              onClose={() => setBlogModalOpen(false)}
              post={post}
            />
          )}
        </>
      )}

      {stage && noPostsAdded && (
        <>
          <div className="white-block discussion__white-block">
            <StageHeader title={stage.title} type="DISCUSSION" description={stage.description}>
              <ItemLifecycle item={stage} type={"STAGE"} />
            </StageHeader>
          </div>

          <div className="discussion__comments">
            <div className="discussion__comments-title">Обсуждений пока нет.</div>
          </div>
        </>
      )}
    </section>
  );
};

export default DiscussionPage;
