import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";

import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getUrlParam, setUrlParam } from "../../../utils/urlUtils";
import { scrollToTop } from "../../../utils";

import ProjectService from "../../../services/projectService";
import ProposalService from "../../../services/proposalService";
import StageService from "../../../services/stageService";
import { setAppColor, showErrorInfoModal, showFooter, showHeader } from "../../../store/actions/LayoutActions";

import { AppColorsEnum } from "../../../App.interface";
import { RootState } from "../../../types/State.interface";
import { Stage } from "../../../types/Stage.interface";

import { criteriaDescription, getGenerationFolders } from "./Generation.constants";

import StageHeader from "../../presentational/StageHeader/StageHeader";
import ItemLifecycle from "../../presentational/ItemLifecycle/ItemLifecycle";
import ItemStats from "../../presentational/ItemStats/ItemStats";
import Tabs from "../../presentational/Tabs/Tabs";
import ProposalCard from "../../presentational/Cards/ProposalCard/ProposalCard";
import { Button, Loader } from "@crowd/ui-kit";

import "./Generation.scss";
import { ProposalTypeEnum } from "../../../types/Proposal.interface";
import useQuery from "../../../utils/hooks/useQuery";
import { ApiStatusCode } from "../../../types/Common.interface";
import GenerationFormContainer from "./GenerationContainer/GenerationFormContainer";
import { LocalStorageKeys, LocalStorageService } from "../../../services/LocalStorage.service";
import { useForceUpdate } from "../../../utils/hooks/useForceUpdate";
import { GenerationFormType } from "./GenerationForm/GenerationForm";
import { selectIsUserReady } from "../../../store/selectors/profile";

interface LocationState {
  fromOtherPage?: boolean;
  localDraft?: boolean;
}

const GenerationPage = () => {
  const location = useLocation<LocationState>();
  const query = useQuery();
  const history = useHistory();
  const params = useParams<{ stageId: string }>();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const formRef = useRef<GenerationFormType>();

  const [stageId, setStageId] = useState(params.stageId);
  const [stage, setStage] = useState<Stage>();
  const [loading, setLoading] = useState<boolean>(true);
  const [drafts, setDrafts] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [criteria, setCriteria] = useState<string>("");
  const currentUser = useSelector((state: RootState) => state.user.userDetails);
  const [resetProposeFormCounter, setResetProposeFormCounter] = useState(0);
  const [localDraftId, setLocalDraftId] = useState<string>();
  const isUserReady = useSelector(selectIsUserReady);

  const getInitialFolder = () => {
    return getUrlParam("type", location.search) || "propose";
  };

  const [generationFolders, setGenerationFolders] = useState(getGenerationFolders());
  const [currentFolderId, setCurrentFolderId] = useState(getInitialFolder());

  useEffect(() => {
    if (stageId && currentUser && (!currentUser.loggedIn || isUserReady)) {
      loadData();
    }
  }, [stageId, currentUser, isUserReady]);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.WHITE));
    dispatch(showHeader());
    dispatch(showFooter());
  }, []);

  const _setDrafts = (drafts) => {
    const draftsContent = drafts.map((draft) => {
      const draftContent = draft.content[0];
      draftContent.draftId = draft.id;

      return draftContent;
    });

    setDrafts(draftsContent);
  };

  const loadData = (update = false) => {
    const promises = [
      ProposalService.getPersonalProposals(stageId, `${ProposalTypeEnum.SUBMITTED}&status=${ProposalTypeEnum.REJECTED}`, 0, 500),
      ProposalService.getStageDrafts(stageId),
    ];

    if (!update) promises.push(fetchStageData(), getCriteria());

    Promise.all(promises)
      .then(([{ data: proposals }, { data: drafts }]) => {
        setProposals(proposals.filter((proposal) => proposal.status !== "DRAFT"));
        _setDrafts(drafts);
        setGenerationFolders(getGenerationFolders(proposals, drafts));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const fetchStageData = async () => {
    try {
      const { status, message, data } = await StageService.getStages(stageId);
      if (status === ApiStatusCode.OK) {
        setStage(data);
      } else {
        throw new Error(message);
      }
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    }
  };

  const getCriteria = () => {
    return ProjectService.getCriteria().then(({ data }) => setCriteria(data || ""));
  };

  const onFolderClick = (folder) => {
    setCurrentFolderId(folder.id);
    setUrlParam("type", folder.id, history);
    setTimeout(() => {
      forceUpdate();
    });
  };

  const handleTabLeave = (tab) => {
    history.replace(history.location.pathname);
    LocalStorageService.removeData(LocalStorageKeys.GenerationDraft);
  };

  const renderTabs = () => {
    return (
      <div className="generation__content-switch">
        <Tabs
          currentTabId={currentFolderId}
          tabs={generationFolders}
          onTabClick={onFolderClick}
          onTabLeave={handleTabLeave}
          isUseMobileTab={true}
        />
      </div>
    );
  };

  const renderCriteria = () => {
    return (
      <div style={{ display: currentFolderId === "criteria" ? "block" : "none" }}>
        <section className="generation__criteria-description">{criteriaDescription}</section>
        <section className="generation__criteria-lists">
          <div dangerouslySetInnerHTML={{ __html: criteria }} />
          <Button
            text="Предложить идею"
            size={"m"}
            type="filled"
            onClick={() => {
              onFolderClick({ id: "propose" });
              scrollToTop();
            }}
          />
        </section>
      </div>
    );
  };

  const renderDrafts = (ideas) => {
    return (
      <section className="generation__ideas" style={{ display: currentFolderId === "drafts" ? "block" : "none" }}>
        {ideas.map((proposal, idx) => (
          <ProposalCard
            proposal={proposal}
            fromGeneration={true}
            key={proposal.id}
            index={idx}
            onDeleteDraftSuccess={() => {
              setLoading(false);
              loadData(true);
              if (proposal.draftId === formRef.current?.getDraftId()?.draftId) {
                formRef.current?.setDraftId(null);
              }
            }}
            showHistory
            showRejectReason={proposal.status === ProposalTypeEnum.REJECTED}
            onDeleteDraftFailure={() => setLoading(false)}
            canEdit={true}
            canDelete={true}
          />
        ))}
      </section>
    );
  };

  const renderSentIdeas = (ideas) => {
    return (
      <section className="generation__ideas" style={{ display: currentFolderId === "sent" ? "block" : "none" }}>
        {ideas.map((proposal, idx) => (
          <ProposalCard
            proposal={proposal}
            fromGeneration={true}
            key={proposal.id}
            index={idx}
            onDeleteDraftSuccess={() => {
              setLoading(false);
              loadData(true);
              if (proposal.draftId === formRef.current?.getDraftId()?.draftId) {
                formRef.current?.setDraftId(null);
              }
            }}
            showHistory
            showRejectReason={proposal.status === ProposalTypeEnum.REJECTED}
            onDeleteDraftFailure={() => setLoading(false)}
            canEdit={proposal.status === "REJECTED"}
            canDelete={false}
          />
        ))}
      </section>
    );
  };

  const renderPropose = () => {
    if (!stage || currentFolderId !== "propose") return;

    return (
      <div style={{ display: currentFolderId === "propose" ? "block" : "none" }}>
        <GenerationFormContainer
          ref={formRef}
          stage={stage}
          showFormDescription={false}
          showTaskDescription={false}
          showDraftButton={Boolean(query.isDeclined !== "true")}
          showRejectReason={Boolean(query.showReason === "true")}
          draftAutoSubmitDelay={60}
          canSubmitDraft={drafts?.length < 10}
          onStageSelect={(stage) => history.push(`generation/${stage.id}`)}
          onFormClose={() => history.push("/")}
          onStageModalClose={() => history.push("/")}
          onSubmit={() => loadData(true)}
          onLocalDraftId={setLocalDraftId}
        />
      </div>
    );
  };

  const getClassListForContent = () => {
    let classlist = "generation__content";
    if (currentFolderId) {
      classlist += ` generation__content--${currentFolderId}`;
    }
    return classlist;
  };

  return (
    <section className="generation">
      {loading && <Loader />}

      {stage && (
        <StageHeader title={stage.title} type="GENERATION">
          <ItemLifecycle item={stage} type={"STAGE"} />
          <ItemStats icon={"idea"} count={stage.stats?.proposalCount || 0} type={"IDEAS"} />
        </StageHeader>
      )}

      {stage?.status === "STARTED" &&
        (currentUser?.teamMember ? (
          <div className="generation__is-team-member">Вы не можете отправлять идеи, так как представляете команду проекта.</div>
        ) : (
          <>
            {renderTabs()}

            <div className="stage-body-wrapper">
              <div className={getClassListForContent()}>
                {renderPropose()}
                {renderCriteria()}
                {renderDrafts(drafts)}
                {renderSentIdeas(proposals)}
              </div>
            </div>
          </>
        ))}

      {stage?.status === "NEW" && <div className="generation__stage-announce">Этап ещё не стартовал</div>}

      {stage?.status === "FINISHED" && <div className="generation__stage-finished">Этап завершен!</div>}
    </section>
  );
};

export default GenerationPage;
