import { AttachmentPreview, Button } from "@crowd/ui-kit";
import dayjs from "dayjs";
import { FC } from "react";
import { SelectionIdeaInfoProps } from "./SelectionIdeaInfo.interface";
import "./SelectionIdeaInfo.scss";
import ProposalHistory from "../../../../presentational/Cards/ProposalCard/ProposalHistory/ProposalHistory";
import SelectionAcceptForm from "../SelectionAcceptForm/SelectionAcceptForm";
import { SelectionAcceptFormType } from "../SelectionAcceptForm/SelectionAcceptForm.interface";
import { useDispatch } from "react-redux";
import { EIdeaProcessType } from "../../constants/constants";
import { useSelectionOnEditTagSuccess } from "../../hooks/hooks";
import { selectionSetIdeaProcessAndType, selectionSetIdeaProcess } from "../../../../../store/actions/SelectionActions";

const SelectionIdeaInfo: FC<SelectionIdeaInfoProps> = ({
  idea,
  showTag = true,
  canEditTag = false,
  showEditTag = false,
  showAttachments = true,
  showHistory = false,
  showIsMain = false,
}) => {
  const dispatch = useDispatch();

  const handleChangeEditTagState = (state: boolean) => {
    if (state) {
      dispatch(selectionSetIdeaProcessAndType(idea.id, EIdeaProcessType.TAG_EDITING));
    } else {
      dispatch(selectionSetIdeaProcess(undefined));
    }
  };

  const handleEditTagSuccess = useSelectionOnEditTagSuccess();
  return (
    <div className="selection-idea-info">
      <div className="selection-idea-info__title break-word">
        {showIsMain && idea.main && <i className="ic ui-icon-Component4073 selection-idea-info__main-mark" />}
        {idea.title}
      </div>
      <div className="selection-idea-info__date">
        {dayjs(idea.lastSubmitted || idea.lastEdited || idea.submissionDate).format("DD.MM.YYYY HH:mm")}
      </div>
      {!!idea.items?.length && (
        <ol className={`selection-idea-info__list ${idea.items.length === 1 ? "selection-idea-info__list_alone" : ""}`}>
          {idea.items.map(({ valueRaw, orderNumber }) => (
            <li key={orderNumber}>
              <div className="break-word">
                <strong>{idea.stage.taskItems[orderNumber]?.title}</strong>
              </div>
              <div className="break-word" dangerouslySetInnerHTML={{ __html: valueRaw }}></div>
            </li>
          ))}
        </ol>
      )}

      {showTag && idea.tag && (
        <>
          <div className="selection-idea-info__tag">
            <i className="ic ui-icon-drive-file" /> {idea.tag}{" "}
            {canEditTag && !showEditTag && (
              <Button
                classNames="selection-idea-info__tag__edit"
                type="text-button"
                text=""
                icon="ui-icon ui-icon-edit"
                onClick={() => handleChangeEditTagState(true)}
              />
            )}
          </div>

          {showEditTag && (
            <SelectionAcceptForm
              type={SelectionAcceptFormType.EDIT}
              stageId={idea.selectionStageId}
              idea={idea}
              currentTag={idea.tag}
              onSuccess={() => {
                handleEditTagSuccess();
                handleChangeEditTagState(false);
              }}
              onClose={() => handleChangeEditTagState(false)}
            />
          )}
        </>
      )}

      {showAttachments && !!idea.attachments?.length && (
        <div className="selection-idea-info__attachments">
          {idea.attachments.map((attachment) => (
            <AttachmentPreview key={attachment.id} attachment={attachment} />
          ))}
        </div>
      )}

      {showHistory && <ProposalHistory proposal={idea} />}
    </div>
  );
};

export default SelectionIdeaInfo;
