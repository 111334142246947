import { FC, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsUserChild, selectIsUserReady } from "../../../../store/selectors/profile";
import { logout } from "../../../../store/actions/UserActions";
import "./AuthChildForbidden.scss";
import { Button, Modal } from "@crowd/ui-kit";

const AuthChildForbidden: FC = () => {
  const isUserChild = useSelector(selectIsUserChild);
  const isUserReady = useSelector(selectIsUserReady);
  const dispatch = useDispatch();
  const isModalOpen = isUserReady && isUserChild;
  const onClose = () => {
    dispatch(logout());
  };

  return (
    isModalOpen && (
      <Modal classNames="auth-child-forbidden-modal" isOpen onClose={onClose}>
        <Fragment key={"body"}>
          <div className="auth-child-forbidden-modal__title">14+</div>

          <div className="auth-child-forbidden-modal__text">Данный раздел предназначен для пользователей старше 14 лет.</div>
        </Fragment>
        <Fragment key={"footer"}>
          <div className="auth-child-forbidden-modal__buttons">
            <Button type="filled" text="Хорошо" onClick={onClose} size={"m"} />
          </div>
        </Fragment>
      </Modal>
    )
  );
};

export default AuthChildForbidden;
