import {
  EIdeaProcessType,
  filterTypesSearchSelect,
} from "../../components/pages/Selection/constants/constants";
import ProposalService from "../../services/proposalService";
import StageService from "../../services/stageService";
import UserService from "../../services/userService";
import { ApiStatusCode } from "../../types/Common.interface";
import { TSelectionState } from "../../types/Selection.interface";
import { Stage } from "../../types/Stage.interface";
import { RootState } from "../../types/State.interface";
import { SelectionFolders } from "../../utils/constants/proposal";
import { profileUserDetailsSelector } from "../selectors/profile";
import {
  selectionFilterSelector,
  selectionIsForwardedShowAllSelector,
  selectionPageSelector,
  selectionSelectedTagsForCombiningSelector,
  selectionTagFilterSelector,
} from "../selectors/selection";
import { showErrorToast } from "./ToastActions";
import { showConfirmInfoModal, showErrorInfoModal } from "./LayoutActions";
import ProjectService from "../../services/projectService";
import { stageSelector } from "../selectors/stage";
import { ProposalInterface } from "../../types/Proposal.interface";
import { batch } from "react-redux";

export const SELECTION_SET_GENERATION_STAGE = "SELECTION_SET_GENERATION_STAGE";
export const SELECTION_SET_COUNTS = "SELECTION_SET_COUNTS";
export const SELECTION_SET_TAGS = "SELECTION_SET_TAGS";
export const SELECTION_SET_REJECT_REASONS = "SELECTION_SET_REJECT_REASONS";
export const SELECTION_SET_EXPERT = "SELECTION_SET_EXPERT";
export const SELECTION_SET_IDEAS = "SELECTION_SET_IDEAS";
export const SELECTION_SET_IDEAS_LOADING = "SELECTION_SET_IDEAS_LOADING";
export const SELECTION_SET_IDEAS_PAGINATION = "SELECTION_SET_IDEAS_PAGINATION";
export const SELECTION_SET_IDEAS_CURRENT_PAGE =
  "SELECTION_SET_IDEAS_CURRENT_PAGE";
export const SELECTION_SET_IDEA_PROCESS_ID = "SELECTION_SET_IDEA_PROCESS_ID";
export const SELECTION_SET_IDEA_PROCESS_TYPE =
  "SELECTION_SET_IDEA_PROCESS_TYPE";
export const SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY =
  "SELECTION_SET_IDEA_SHOW_TRANSFER_HISTORY";
export const SELECTION_SET_FILTER = "SELECTION_SET_FILTER";
export const SELECTION_SET_FILTER_TYPE_SEARCH =
  "SELECTION_SET_FILTER_TYPE_SEARCH";
export const SELECTION_SET_SEARCH_FILTER_VALUE =
  "SELECTION_SET_SEARCH_FILTER_VALUE";
export const SELECTION_SET_FILTER_SEARCH_RES =
  "SELECTION_SET_FILTER_SEARCH_RES";
export const SELECTION_SET_SELECTED_TAGS_FOR_COMBINING =
  "SELECTION_SET_SELECTED_TAGS_FOR_COMBINING";
export const SELECTION_SET_IDEAS_FOR_COMBINING =
  "SELECTION_SET_IDEAS_FOR_COMBINING";
export const SELECTION_SET_IS_TAG_EDITING = "SELECTION_SET_IS_TAG_EDITING";
export const SELECTION_SET_IS_COMBINING_TAGS =
  "SELECTION_SET_IS_COMBINING_TAGS";
export const SELECTION_SET_IS_STAGE_FINISHED =
  "SELECTION_SET_IS_STAGE_FINISHED";
export const SELECTION_SET_IS_FORWARDED_SHOW_ALL =
  "SELECTION_SET_IS_FORWARDED_SHOW_ALL";

export const selectionSetIdeaProcess =
  (ideaProcessId: ProposalInterface["id"]) => (dispatch) => {
    dispatch({
      type: SELECTION_SET_IDEA_PROCESS_ID,
      payload: ideaProcessId,
    });
  };

export const selectionSetIdeaProcessAndType =
  (ideaProcessId: ProposalInterface["id"], ideaProcessType: EIdeaProcessType) =>
  (dispatch) => {
    batch(() => {
      dispatch({
        type: SELECTION_SET_IDEA_PROCESS_ID,
        payload: ideaProcessId,
      });
      dispatch({
        type: SELECTION_SET_IDEA_PROCESS_TYPE,
        payload: ideaProcessType,
      });
    });
  };

export const selectionGetGenerationStage =
  (selectionStageId: Stage["id"]) => async (dispatch) => {
    try {
      const res =
        await StageService.getGenerationStageBySelectionStage(selectionStageId);

      dispatch({
        type: SELECTION_SET_GENERATION_STAGE,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const selectionGetCounts =
  (stageId: Stage["id"]) => async (dispatch) => {
    try {
      const res = await ProposalService.getSelectionCount(stageId);

      dispatch({
        type: SELECTION_SET_COUNTS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const selectionGetTags = (stageId: Stage["id"]) => async (dispatch) => {
  try {
    const res = await ProposalService.getTags(stageId);

    dispatch({
      type: SELECTION_SET_TAGS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const selectionGetRejectReasons =
  (stageId: Stage["id"]) => async (dispatch) => {
    try {
      const res = await ProposalService.getRejectReasons(stageId);

      dispatch({
        type: SELECTION_SET_REJECT_REASONS,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const selectionGetExperts = () => async (dispatch) => {
  try {
    const res = await UserService.findUser({ type: "EXPERT" });

    dispatch({
      type: SELECTION_SET_EXPERT,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

const selectionIdeaFolderGetMethods = {
  [SelectionFolders.INCOMING]: ProposalService.getSelectionIncoming,
  [SelectionFolders.ACCEPTED]: ProposalService.getSelectionAccepted,
  [SelectionFolders.REJECTED]: ProposalService.getSelectionRejected,
  [SelectionFolders.FORWARDED]: ProposalService.getSelectionForwarded,
};

interface TSelectionGetIdeasParams {
  stageId: Stage["id"];
  folder: SelectionFolders;
  proposalId?: string;
}

export const selectionGetIdeas =
  ({ stageId, folder, proposalId }: TSelectionGetIdeasParams) =>
  async (dispatch, getState: () => RootState) => {
    dispatch({
      type: SELECTION_SET_IDEAS_LOADING,
      payload: true,
    });

    try {
      const state = getState();
      const filter = selectionFilterSelector(state);
      const page = selectionPageSelector(state);
      const { id: currentUserId, chiefExpert } =
        profileUserDetailsSelector(state);
      const isForwardedShowAll =
        folder === SelectionFolders.FORWARDED &&
        selectionIsForwardedShowAllSelector(state);
      const isChiefExpertShowAll =
        chiefExpert &&
        [SelectionFolders.ACCEPTED, SelectionFolders.REJECTED].includes(folder);

      const res = await selectionIdeaFolderGetMethods[folder](
        folder === SelectionFolders.INCOMING
          ? { stageId, proposalId }
          : {
              stageId,
              ...filter,
              size: 20,
              page,
              ...(!isForwardedShowAll && !isChiefExpertShowAll
                ? { expertId: currentUserId }
                : {}),
            }
      );

      switch (res.status) {
        case ApiStatusCode.ILLEGAL_INPUT:
          throw new Error(res.message);
        case ApiStatusCode.OK:
          dispatch({
            type: SELECTION_SET_IDEAS,
            payload: Array.isArray(res.data) ? res.data : [res.data],
          });
          break;
        default:
          dispatch({
            type: SELECTION_SET_IDEAS,
            payload: [],
          });
          break;
      }

      dispatch({
        type: SELECTION_SET_IDEAS_PAGINATION,
        payload: "paging" in res ? res.paging : null,
      });

      if ("paging" in res) {
        const total = res.paging.totalPages;
        if (total !== 0 && total < page + 1)
          dispatch({
            type: SELECTION_SET_IDEAS_CURRENT_PAGE,
            payload: total - 1,
          });
      }
    } catch (error: any) {
      dispatch(showErrorToast(error.message));
    }

    dispatch({
      type: SELECTION_SET_IDEAS_LOADING,
      payload: false,
    });
  };

export const selectionSetFilter =
  (filter: TSelectionState["filter"]) => (dispatch) => {
    dispatch({
      type: SELECTION_SET_FILTER,
      payload: filter,
    });
  };

export const selectionSetDefaultFilter = () => (dispatch) => {
  const filter = Object.fromEntries(
    Object.entries(
      Object.fromEntries(new URLSearchParams(window.location.search))
    ).map(([k, v]) => [k, v === "true" ? true : v === "false" ? false : v])
  );

  dispatch({
    type: SELECTION_SET_FILTER,
    payload: filter,
  });

  dispatch({
    type: SELECTION_SET_FILTER_TYPE_SEARCH,
    payload:
      filterTypesSearchSelect.find(({ value }) => value in filter) ||
      filterTypesSearchSelect[0],
  });
};

export const selectionStopStage = () => (dispatch, getState) => {
  const stage = stageSelector(getState());

  dispatch(
    showConfirmInfoModal(
      "Вы действительно хотите завершить этап?",
      async () => {
        try {
          const { status, message } = await ProjectService.stopStage(stage.id);
          if (status !== ApiStatusCode.OK) {
            throw new Error(message);
          }
          window.location.reload();
        } catch (e: any) {
          dispatch(showErrorInfoModal(e.message));
        }
      }
    )
  );
};

export const selectionConfirmSelectedMainIdea =
  (folder: SelectionFolders, selectedIdea: ProposalInterface) =>
  async (dispatch, getState) => {
    const state = getState();
    const stageId = stageSelector(state).id;
    const tagFilter = selectionTagFilterSelector(state);
    const selectedTagsForCombining =
      selectionSelectedTagsForCombiningSelector(state);

    dispatch({ type: SELECTION_SET_IS_COMBINING_TAGS, payload: true });
    try {
      const { status, message } = await ProposalService.selectionTagUnite({
        stageId,
        targetTagId: tagFilter.id,
        sourceTagIds: selectedTagsForCombining.map(({ id }) => id),
        mainProposalId: selectedIdea.id,
      });
      if (status !== ApiStatusCode.OK) {
        throw new Error(message);
      }
      dispatch({ type: SELECTION_SET_IDEAS_FOR_COMBINING, payload: undefined });
      dispatch(selectionGetTags(stageId));
      dispatch(
        selectionGetIdeas({
          stageId,
          folder,
        })
      );
    } catch (e: any) {
      dispatch(showErrorInfoModal(e.message));
    } finally {
      dispatch({ type: SELECTION_SET_IS_COMBINING_TAGS, payload: false });
    }
  };
