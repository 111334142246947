import React, { useEffect, useState } from "react";

import "./Main.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/State.interface";
import PageTitle from "../../presentational/PageTitle/PageTitle";
import { getPlainFromHtml } from "../../../utils";
import StagesTape from "./StagesTape/StagesTape";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import dayjs from "dayjs";
import UserAvatar from "../../presentational/UserAvatar/UserAvatar";
import { getRootComments } from "../../../store/actions/CommentActions";
import MainDiscussion from "./MainDiscussion/MainDiscussion";
import { Stage } from "../../../types/Stage.interface";
import { Discussion } from "../../../types/Discussion.interface";
import { setAppColor, showFooter, showHeader } from "../../../store/actions/LayoutActions";
import ItemLifecycle from "../../presentational/ItemLifecycle/ItemLifecycle";
import { AppColorsEnum } from "../../../App.interface";
import { Link, useHistory } from "react-router-dom";
import ResendEmailModal from "../../containers/Modals/ResendEmailModal/ResendEmailModal";
import BlogService from "../../../services/blogService";
import { getUrlParam } from "../../../utils/urlUtils";
import { BlogPost } from "../../../types/BlogPost.interface";
import { useMediaQuery } from "react-responsive";
import { phoneWidth, shortDesktopWidth, tabletWidth } from "../../../utils/constants/widthConstants";
import MainActionsStat from "./MainActionsStat/MainActionsStat";
import ProjectParticipants from "./ProjectParticipants/ProjectParticipants";
import { getTopParticipants } from "../../../store/actions/ParticipantsActions";
import EventsTrackParentIdContext from "../../containers/EventsTrack/EventsTrackParentIdContext";
import EventsTrackWrapperClick from "../../containers/EventsTrack/wrappers/EventsTrackWrapperClick";
import EventsTrackWrapperScroll from "../../containers/EventsTrack/wrappers/EventsTrackWrapperScroll";
import BannerLine from "./BannerLine/BannerLine";
import { selectIsNeedAfterRegistrationForm, selectIsUserChild } from "../../../store/selectors/profile";

SwiperCore.use([Navigation]);

const MainPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.userDetails);
  const project = useSelector((state: RootState) => state.project.current);
  const stages = useSelector((state: RootState) => state.stage.allStages);
  const projectStat = useSelector((state: RootState) => state.project.statistic);
  const experts = useSelector((state: RootState) => state.user.experts);
  const discussions = useSelector((state: RootState) => state.discussion.byType?.PROJECT_DISCUSSIONS);
  const topParticipants = useSelector((state: RootState) => state.participants.topParticipants.data);
  const isUserChild = useSelector(selectIsUserChild);
  const isNeedAfterRegistrationForm = useSelector(selectIsNeedAfterRegistrationForm);

  const isTablet = useMediaQuery({ query: `(max-width: ${tabletWidth}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${phoneWidth}px)` });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${shortDesktopWidth + 1}px)`,
  });
  const isTabletOnly = useMediaQuery({
    query: `(min-width: ${phoneWidth}px ) and (max-width: ${shortDesktopWidth}px)`,
  });

  const [actualPosts, setActualPosts] = useState(null);
  const [curPost, setCurPost] = useState<BlogPost>(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>(null);

  const [resendEmailModalOpen, setResendEmailModalOpen] = useState<boolean>(false);

  useEffect(() => {
    BlogService.getAllByBlogId("project_diary_id", "createdTimestamp,desc", false).then((response) => {
      setActualPosts(response.data);
    });
  }, []);

  useEffect(() => {
    dispatch(setAppColor(AppColorsEnum.LIGHTBLUE));
    dispatch(showHeader());
    dispatch(showFooter());
    dispatch(getTopParticipants());
  }, []);

  useEffect(() => {
    setResendEmailModalOpen(user && !isNeedAfterRegistrationForm && !user.emailVerified && !isUserChild);
  }, [user, isUserChild, isNeedAfterRegistrationForm]);

  useEffect(() => {
    if (stages && discussions) {
      getStartedDiscussions().map((discussion) => dispatch(getRootComments({ discussionId: discussion.id })));
    }
  }, [stages, discussions]);

  const getStartedDiscussionStages = (): Stage[] => {
    return stages?.filter((stage) => stage.type === "SIMPLE_DISCUSSION" && stage.status === "STARTED");
  };

  const getStartedDiscussions = (): Discussion[] => {
    return discussions?.filter((discussion) => !!getStartedDiscussionStages().find((stage) => stage.id === discussion.stageId)) || [];
  };

  const getSliderHeader = (title, items: any[], [nextClass, prevClass], link?: string) => {
    return (
      <div className="main__slider-top">
        {!link ? (
          <h3>{title}</h3>
        ) : (
          <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: [title] }}>
            <Link className="main__slider-top-link" to={link}>
              <h3>{title}</h3>
            </Link>
          </EventsTrackWrapperClick>
        )}
        {items?.length > 1 && (
          <div className="main__slider-arrows">
            <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["слайдера влево"] }}>
              <i className={`ui-icon-keyboard-arrow-left ui-icon ${prevClass}`} />
            </EventsTrackWrapperClick>
            <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["слайдера вправо"] }}>
              <i className={`ui-icon-keyboard-arrow-right ui-icon ${nextClass}`} />
            </EventsTrackWrapperClick>
          </div>
        )}
      </div>
    );
  };

  const goToExpert = (id: string) => {
    history.push("/team?id=" + id);
  };

  // RENDER

  const renderSlideContent = (post) => {
    if (post.rawContent.includes("iframe")) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(post.rawContent, "text/html");
      const iframe = doc.getElementsByTagName("iframe")[0];
      if (iframe) {
        return getUrlParam("crdescr", iframe.src); // description
      }
    }

    return getPlainFromHtml(post.content);
  };

  const renderActualSlider = () => {
    const [next, prev] = ["main__actual-next", "main__actual-prev"];

    const getClasses = () => {
      let classlist = "main__actual";
      if (!actualPosts.length) classlist += " main__actual-empty";
      return classlist;
    };

    const getLink = (post: BlogPost): string => {
      let date;
      if (post) {
        date = dayjs(post.createdTimestamp).format("DD.MM.YYYY");
      } else {
        date = dayjs().format("DD.MM.YYYY");
      }

      let link = `/actual?date=${date}`;
      if (!actualPosts.length && user?.teamMember) {
        return (link += `&create=true`);
      }

      return link;
    };

    return (
      actualPosts && (
        <EventsTrackParentIdContext.Provider value={["PAGE_MAIN", "BLOCK_ACTUAL"]}>
          <div className={getClasses()}>
            {getSliderHeader("Актуальное", actualPosts, [next, prev], getLink(curPost))}
            {actualPosts?.length === 0 ? (
              <div className="main__actual-noposts">Записей пока нет</div>
            ) : (
              <Swiper
                className="main__actual-container"
                autoHeight={true}
                spaceBetween={20}
                slidesPerView={1}
                loop={true}
                navigation={{
                  nextEl: `.${next}`,
                  prevEl: `.${prev}`,
                }}
                onSlideChange={() => {
                  setCurPost(actualPosts[swiperInstance?.realIndex || 0] || null);
                }}
                onSwiper={(instance) => {
                  setSwiperInstance(instance);
                }}
              >
                {actualPosts.map((post, key) => (
                  <SwiperSlide className="main__actual-item" key={key}>
                    <div className="main__actual-item-title">
                      <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["Текст новости"] }}>
                        <Link to={getLink(post)}>
                          <h5>{post.title}</h5>
                        </Link>
                      </EventsTrackWrapperClick>
                    </div>
                    <div className="main__actual-item-date">
                      <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["Текст новости"] }}>
                        <Link to={getLink(post)}>{dayjs(post.createdTimestamp).format("DD.MM.YYYY")}</Link>
                      </EventsTrackWrapperClick>
                    </div>
                    <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["Текст новости"] }}>
                      <Link to={getLink(post)}>
                        <div
                          className="main__actual-item-text"
                          dangerouslySetInnerHTML={{
                            __html: renderSlideContent(post),
                          }}
                        />
                      </Link>
                    </EventsTrackWrapperClick>
                    <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["Читать дальше"] }}>
                      <Link className="main__actual-item-more" to={getLink(post)}>
                        Читать дальше
                      </Link>
                    </EventsTrackWrapperClick>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </EventsTrackParentIdContext.Provider>
      )
    );
  };

  const renderExpertsSlider = () => {
    const [next, prev] = ["main__experts-next", "main__experts-prev"];
    const scrollProps = isMobile
      ? {
          id: ["DOCSCROLL"],
          needParent: true,
        }
      : { id: null };
    return (
      experts && (
        <EventsTrackParentIdContext.Provider value={["PAGE_MAIN", "BLOCK_EXPERTS"]}>
          <EventsTrackWrapperScroll {...scrollProps}>
            <div className="main__experts">
              {getSliderHeader("Эксперты проекта", experts, [next, prev], "/team")}
              <Swiper
                className="main__experts-container"
                spaceBetween={isMobile ? 16 : 20}
                slidesPerView={1}
                loop={true}
                loopAdditionalSlides={3}
                width={isMobile ? 292 : 332}
                navigation={{
                  nextEl: `.${next}`,
                  prevEl: `.${prev}`,
                }}
              >
                {experts.map((expert, key) => (
                  <SwiperSlide key={key}>
                    <EventsTrackWrapperClick id={["BUTTON", "CLICK"]} needParent params={{ replace: ["Эксперт"] }}>
                      <div className="main__experts-item" onClick={() => goToExpert(expert.id)}>
                        <UserAvatar user={expert} size={isTablet || isMobile ? "XS" : "S"} />
                        <div className="main__experts-info">
                          <div className="main__experts-name">
                            {expert.firstName} {expert.lastName}
                          </div>
                          <div className="main__experts-position">{expert.position}</div>
                        </div>
                      </div>
                    </EventsTrackWrapperClick>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </EventsTrackWrapperScroll>
        </EventsTrackParentIdContext.Provider>
      )
    );
  };

  const renderProjectParticipants = () => {
    return (
      <EventsTrackParentIdContext.Provider value={["PAGE_MAIN", "BLOCK_PARTICIPANTS"]}>
        <EventsTrackWrapperScroll id={["DOCSCROLL"]} needParent>
          <div className="main__participants">
            <h3>Участники проекта</h3>
            <ProjectParticipants participants={topParticipants} />
          </div>
        </EventsTrackWrapperScroll>
      </EventsTrackParentIdContext.Provider>
    );
  };

  return (
    <div className="main">
      <BannerLine />
      <div className="white-block main__white-block">
        <div className="main__page-title">
          <PageTitle text={project?.title || ""} />
        </div>
        {!!project && (
          <div className="main__stats-wrap">
            <ItemLifecycle item={project} isMain={true} type={"PROJECT"} />
            {isTabletOnly && <MainActionsStat project={project} projectStat={projectStat} />}
          </div>
        )}
        <div className="main__actions">
          <div className="main__actions-left">
            <EventsTrackParentIdContext.Provider value={["PAGE_MAIN", "BLOCK_CARD_STEPS"]}>
              <StagesTape />
            </EventsTrackParentIdContext.Provider>
          </div>
          <div className="main__actions-right">
            {(isMobile || isDesktop) && <MainActionsStat project={project} projectStat={projectStat} />}
            {renderActualSlider()}
            {renderExpertsSlider()}
          </div>
        </div>
        {renderProjectParticipants()}
      </div>
      <MainDiscussion />

      {resendEmailModalOpen && <ResendEmailModal isOpened={resendEmailModalOpen} onClose={() => setResendEmailModalOpen(false)} />}
    </div>
  );
};

export default MainPage;
