// для формата DD.MM.YYYY
export const maskDate = (value: string) => {
  const checkSymbol = (s: string) => !!s && !isNaN(parseInt(s));

  const dd = value.slice(0, 2);
  const mm = checkSymbol(value.charAt(2)) ? value.slice(2, 4) : value.slice(3, 5);
  const yyyy = checkSymbol(value.charAt(5)) ? value.slice(5, 9) : value.slice(6, 10);

  const arr = `${dd}${mm}${yyyy}`.split("");
  let newValue = "";
  for (let i = 0; i < arr.length; i++) {
    if (!checkSymbol(arr[i])) {
      break;
    }
    if (i === 2 || i === 4) {
      newValue += ".";
    }
    newValue += arr[i];
  }

  return newValue;
};
