export interface User {
  acceptedProposals: number;
  accountType: AccountTypeString;
  atLeastParticipant: boolean;
  birthYear: number;
  birthDate: string;
  blocked: boolean;
  blockingExpires: string;
  chiefExpert: boolean;
  commentCount: number;
  complaints: number;
  controlAnswer: string;
  controlQuestion: string;
  description?: string;
  mediaAttachments?: MediaAttachment[];
  email: string;
  emailVerified: boolean;
  exParticipant: boolean;
  expert: boolean;
  expertOrStaff: boolean;
  facilitator: boolean;
  facilitatorIzrg: boolean;
  projectFacilitator: boolean;
  firstName: string;
  frontman: boolean;
  gender: UserGender;
  highestRole: HighestRoleString;
  id: string;
  lastActivityDate: string;
  lastName: string;
  main: boolean;
  mainUserId: string;
  manager: boolean;
  moscowDistrict: string;
  moscowRegion: string;
  needToCompleteQuestionnaire?: boolean;
  online: boolean;
  partiallyFilled: boolean;
  participant: boolean;
  permissions: UserPermissionsString[];
  personalPostCount: number;
  phone: string;
  pictureId: string;
  position: string;
  projectBoss: boolean;
  roles: UserRoleString[];
  socialNetwork?: SocialNetworkLinks;
  ssoid: string;
  submittedProposals: number;
  teamMember: boolean;
  warnings: number;
  passwordLastChanged: any;
  passwordExpired: any;
  loggedIn: boolean;
  userType: UserTypeEnum;
  showName: boolean;
  child: boolean;
}

export enum AccountType {
  NATIVE,
  FACEBOOK,
  TWITTER,
  VK,
  EMP,
  SUDIR,
}

export type AccountTypeString = keyof typeof AccountType;

export enum UserGender {
  UNKNOWN = "UNKNOWN",
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum UserTypeEnum {
  FULL = "FULL",
  STANDARD = "STANDARD",
  SHORT = "SHORT",
}

export type UserGenderString = keyof typeof UserGender;

export enum HighestRole {
  PROJECT_BOSS,
  MANAGER,
  FACILITATOR,
  CHIEF_EXPERT,
  EXPERT,
  ADMINISTRATOR,
  ANALYST,
  SUPPORT,
  VIP,
  PARTICIPANT,
  EX_PARTICIPANT,
  POTENTIAL,
  PROJECT_FACILITATOR,
}

export type HighestRoleString = keyof typeof HighestRole;

export enum UserPermissions {
  USER_BLOCK = "USER_BLOCK",
  USER_BLOCK_PERMANENT = "USER_BLOCK_PERMANENT",
  USER_REQUEST_BLOCKING = "USER_REQUEST_BLOCKING",
  USER_MAKE_COMPLAINT = "USER_MAKE_COMPLAINT",
  USER_UNBLOCK = "USER_UNBLOCK",
  USER_SEND_MESSAGE = "USER_SEND_MESSAGE",
  USER_WARN = "USER_WARN",
}

export type UserPermissionsString = keyof typeof UserPermissions;

export enum UserRole {
  PROJECT_BOSS = "PROJECT_BOSS",
  MANAGER = "MANAGER",
  FACILITATOR = "FACILITATOR",
  CHIEF_EXPERT = "CHIEF_EXPERT",
  EXPERT = "EXPERT",
  ADMINISTRATOR = "ADMINISTRATOR",
  ANALYST = "ANALYST",
  SUPPORT = "SUPPORT",
  VIP = "VIP",
  PARTICIPANT = "PARTICIPANT",
  EX_PARTICIPANT = "EX_PARTICIPANT",
  POTENTIAL = "POTENTIAL",
  PROJECT_FACILITATOR = "PROJECT_FACILITATOR",
  FACILITATOR_IZRG = "FACILITATOR_IZRG",
}

export type UserRoleString = keyof typeof UserRole;

export interface ProfileState {
  userDetails: User | null;
  loggedIn: boolean;
  error: string;
  isFetching: boolean;
  experts: User[] | null;
}

export interface SocialNetworkLinks {
  facebook?: string;
  twitter?: string;
  instagram?: string;
  vkontakte?: string;
  odnoklassniki?: string;
}

export interface MediaAttachment {
  id: string;
  type?: "IMAGE" | "VIDEO";
  imageAttachment?: ImageAttachment;
  videoAttachment?: VideoAttachment;
}

export interface ImageAttachment {
  id: string;
  fileName?: string;
  size: number;
  parentId?: string;
  hasThumbnail?: boolean;
}

export interface VideoAttachment {
  id: string;
  fileName?: string;
  size: number;
  parentId?: string;
  hasThumbnail?: boolean;
}

export interface PasswordChangeParams {
  currentPassword?: string;
  password: string;
}
